<template>
    <div>
    <checkbox-field class="mb-4" name="Беременна" v-if="gender==='female'" :value.sync="pregnant"/>
    <table class="table" id="table" cellspacing="2" border="1" cellpadding="5" ref="table">
        <tr>
            <td v-for="title in header" :key="title" type="title" bold="true"><b>{{title}}</b></td>
            <td class="controlCell"><b>Удалить</b></td>
        </tr>    
        <tr v-for="(parameter, index) in params" :key="index" :class="{ disabled: parameter.disabled }">  
            <template v-if="parameter.isTitle === true">
                <td align="left" colspan="3" type="title" bold="true"><b>{{parameter.name}}</b></td>
                <td v-if="!parameter.disabled" class="controlCell" @click="params[index].disabled=true;convertation();"><v-icon color="#F44336">mdi-close</v-icon></td>
                <td v-if="parameter.disabled===true" class="controlCell" @click="params[index].disabled=false;convertation();"><v-icon color="#4CAF50">mdi-arrow-u-left-top</v-icon></td>
            </template> 
            <template v-else>   
                <td align="left">{{parameter.name}}</td>
                
                <td align="center" v-on:click="showInput(index);" :bold="parameter.alert ? true : ''" :style="parameter.alert ? {color:'#ff5252'} : {color:'inherit'}">    
                    <div v-show="parameter.resultInput===true">

                        <v-menu v-model="parameter.menuOpen" :close-on-content-click="false" open-on-focus offset-x>
                            <template v-slot:activator="{ on, attrs }">
                                <input  type="text" v-model="params[index].result" @keyup.up="addResultUp(index-1);" @keyup.down="addResult(index+1);" @keyup.enter="addResult(index+1);" @blur="addResultUnfocus(index);closeMenu(index);" @focusout="addResultUnfocus(index);" :ref="'cell'+index" @focus="$event.target.setSelectionRange(0,100)" v-on="on" v-bind="attrs">
                            </template>

                            <v-list>
                                <v-list-item
                                v-for="(hint,idx) in parameter.hints"
                                :key="'hint'+hint+idx"
                                @click="params[index].result=hint;addResult(index+1)"
                                >
                                <v-list-item-title  style="cursor: pointer;">{{ hint }}</v-list-item-title>
                                </v-list-item>
                            </v-list>
                        </v-menu>
                    </div>
                    <template v-if="parameter.resultInput===false">{{parameter.result}}</template>
                </td>
                <td align="center">{{parameter.norm}}</td>
                <!-- <td align="center" v-html="parameter.unit"></td> -->
                <td v-if="!parameter.disabled" class="controlCell" @click="params[index].disabled=true;convertation();"><v-icon color="#F44336">mdi-close</v-icon></td>
                <td v-if="parameter.disabled===true" class="controlCell" @click="params[index].disabled=false;convertation();"><v-icon color="#4CAF50">mdi-arrow-u-left-top</v-icon></td>
            </template> 
        </tr>    
    </table>    
    </div>
</template>
<script>
import moment from "moment";
import CheckboxField from "@/componentsV2/base/CheckboxField.vue";

export default {
  components: {
    CheckboxField
  },
  props: ['medRecordValue','editorField','birth','gender'],
  data: () => ({
    age:null,
    date:null,
    days:0,
    header: [
        'Тест',
        'Результат',    
        'Норма',
        // 'Ед. измерен.',
    ],
    params: [],
    newMedRecordValue: null,
    firstLoad: false,
    focusChange: false,
    pregnant: false,
    globalParams: [
        {
         name:'Количество (мл)',
         result:'',
         resultInput: false,
         unit:'мл',
         norm: {
            m:'',
            f:'', 
            pw:'',
            g:'', 
            b:'',
         },
         hints:[]
        },
        {
         name:'Цвет',
         result:'с/желтый',
         resultInput: false,
         unit:'',
         norm: {
            m:'с/желтый',
            f:'с/желтый', 
            pw:'с/желтый',
            g:'с/желтый', 
            b:'с/желтый',
         },
         hints:[
            'с/желтый'
         ]
        },
        {
         name:'Прозрачность',
         result:'прозр.',
         resultInput: false,
         unit:'',
         norm: {
            m:'прозр.',
            f:'прозр.', 
            pw:'прозр.',
            g:'прозр.', 
            b:'прозр.',
         },
         hints:['прозр.','мутная','сл/мутная']
        },
        {
         name:'Удельный вес',
         result:'',
         resultInput: false,
         unit:'',
         norm: {
            m:'1,004-1,033',
            f:'1,004-1,033', 
            pw:'1,004-1,033',
            g:'1,004-1,033', 
            b:'1,004-1,033',
         },
         hints:[]
        },
        {
         name:'Реакция',
         result:'',
         resultInput: false,
         unit:'',
         norm: {
            m:'5,0-8,5',
            f:'5,0-8,5', 
            pw:'5,0-8,5',
            g:'5,0-8,5', 
            b:'5,0-8,5',
         },
         hints:[]
        },
        {
         name:'Химическое исследование',
         resultInput:false,
         isTitle: true,
        },
        {
         name:'Белок',
         result:'отр.',
         resultInput: false,
         unit:'',
         norm: {
            m:'отр.',
            f:'отр.', 
            pw:'отр.',
            g:'отр.', 
            b:'отр.',
         },
         hints:['отр.','+','++','+++','++++']
        },
        {
         name:'Сахар',
         result:'отр.',
         resultInput: false,
         unit:'',
         norm: {
            m:'отр.',
            f:'отр.', 
            pw:'отр.',
            g:'отр.', 
            b:'отр.',
         },
         hints:['отр.','+','++','+++','++++']
        },
        {
         name:'Кетоны',
         result:'отр.',
         resultInput: false,
         unit:'',
         norm: {
            m:'отр.',
            f:'отр.', 
            pw:'отр.',
            g:'отр.', 
            b:'отр.',
         },
         hints:['отр.','+','++','+++','++++']
        },
        {
         name:'Билирубин',
         result:'отр.',
         resultInput: false,
         unit:'',
         norm: {
            m:'отр.',
            f:'отр.', 
            pw:'отр.',
            g:'отр.', 
            b:'отр.',
         },
         hints:['отр.','+','++','+++','++++']
        },
        {
         name:'Микроскопическое исследование',
         resultInput:false,
         isTitle: true,
        },
        {
         name:'Лейкоциты',
         result:'отсутств.',
         resultInput: false,
         unit:'',
         norm: {
            m:'един.',
            f:'2-5', 
            pw:'1-6',
            g:'0-7', 
            b:'0-7',
         },
         hints:['отсутств.']
        },
        {
         name:'Эритроциты',
         result:'отсутств.',
         resultInput: false,
         unit:'',
         norm: {
            m:'1-3',
            f:'1-3', 
            pw:'0-3',
            g:'0-2', 
            b:'0-2',
         },
         hints:['отсутств.']
        },
        {
         name:'Цилиндры',
         result:'отсутств.',
         resultInput: false,
         unit:'',
         norm: {
            m:'отсутств.',
            f:'отсутств.', 
            pw:'отсутств.',
            g:'отсутств.', 
            b:'отсутств.',
         },
         hints:['отсутств.']
        },
        {
         name:'Эпителий плоский моч.пузыря',
         result:'отсутств.',
         resultInput: false,
         unit:'',
         norm: {
            m:'0-1',
            f:'2-10', 
            pw:'2-10',
            g:'2-10', 
            b:'0-1',
         },
         hints:['отсутств.']
        },
        {
         name:'Слизь',
         result:'отсутств.',
         resultInput: false,
         unit:'',
         norm: {
            m:'+',
            f:'отсутств.', 
            pw:'отсутств.',
            g:'отсутств.', 
            b:'+',
         },
         hints:['отсутств.','+','++','+++','++++']
        },
        {
         name:'Соли',
         result:'+',
         resultInput: false,
         unit:'',
         norm: {
            m:'+',
            f:'+', 
            pw:'+',
            g:'+', 
            b:'+',
         },
         hints:['отсутств.','+','++','+++','++++']
        },
        {
         name:'Бактерии',
         result:'отсутств.',
         resultInput: false,
         unit:'',
         norm: {
            m:'отсутств.',
            f:'отсутств.', 
            pw:'отсутств.',
            g:'отсутств.', 
            b:'отсутств.',
         },
         hints:['отсутств.','+','++','+++','++++','нитриты']
        },
    ],
  }),
  watch:{
    medRecordValue(){
        if(this.focusChange === false) {
            this.load(true);
            console.log('medRecordValue')
        }
    },  
    pregnant(){
        this.convertation()
        this.load(true)
   
    },
    params: {
        deep: true,
        handler: function() {
            this.resultCheck();
            console.log('param')
        }
    }
  },
  mounted() {
   //pregnant status
   this.pregnant = this.medRecordValue.includes("pregnant") ? true : false;
   this.load();
  },
  methods: {
    load(force=false){
        var now = moment(new Date());
        var birth = moment(this.birth, 'DD.MM.YYYY');
        this.days = now.diff(birth, 'days');    
        this.params = [];
        let norm;
        this.globalParams.forEach(parameter =>{
            if (typeof parameter.norm !== 'undefined') {
                if (this.pregnant) {
                    norm = parameter.norm.pw;
                } else if (this.days >= 6570) {//18 лет
                    norm = this.gender === 'male' ? parameter.norm.m : parameter.norm.f;
                } else {
                    norm = this.gender === 'male' ? parameter.norm.b : parameter.norm.g;
                }
            }    
            
            norm = norm.replace(/,/g, '.');
            if (parameter.isTitle === true){
                this.params.push({'name':parameter.name,'isTitle':true,'disabled':true,});
            } else {
                this.params.push({
                    'name':parameter.name,
                    'result':parameter.result!=='' ? parameter.result : null,
                    'resultInput':parameter.resultInput,
                    //'unit':parameter.unit,
                    'norm':norm, 
                    'alert':false, 
                    'disabled':true,
                    'hints': parameter.hints
                });
            }

        });


        if(!this.firstLoad||force===true) {             
            this.firstLoad = true;
            const regex = /(?<=<)row/ig;
            const regex2 = /(?<=<)col/ig;
            const tempElement = new DOMParser().parseFromString(this.medRecordValue.replaceAll(regex,'tr').replaceAll(regex2,'td'), "text/html")
            
            const table = tempElement.querySelector('table');
            const rows = table ? table.querySelectorAll('tr') : [];
   
            this.params.forEach(parameter =>{
                if (rows.length>0) {
                    rows.forEach(row=>{
                        let cols = row.querySelectorAll('td');
                        if (parameter.name === cols[0].innerHTML&&cols[0].getAttribute('type') === 'title') {
                            parameter.disabled = false;
                        } else if(parameter.name === cols[0].innerHTML&&cols[1]) {
                            parameter.result = cols[1].innerHTML;
                            parameter.resultInput = false;
                            parameter.disabled = false;
                        }
                    })
                }else {
                    parameter.disabled = false;
                }
            })

            this.resultCheck();
        }
        
       
    },
    countPlusSigns(str) {
        return (str.match(/\+/g) || []).length;
    },
    resultCheck(){
        this.params.forEach(parameter=>{
            if (parameter.result) { 
                parameter.result = parameter.result.replace(/,/g, '.');  
                let norm = parameter.norm;
                let result = parameter.result;
                if (norm==='') {
                    parameter.alert = false;
                } else if (result.includes('-')&&result.length>2){//для диапазонов
                    // Извлекаем диапазон из строки result
                    let match = result.match(/\d+\s*-\s*\d+/);
                                 
                    let [rMin, rMax] = match[0].split('-').map(num => parseInt(num.trim()));
                    let [nMin, nMax] = norm.split('-').map(num => parseInt(num.trim()));
    
                    // Проверяем вхождение диапазона
                    parameter.alert = rMin < nMin || rMax > nMax;

                } else if (/\d/.test(norm)) {//для числовых           
                    var range = norm.split('-'); 
                    if (Number(result) < Number(range[0]) || Number(result) > Number(range[1])) {
                        parameter.alert = true;
                    } else {
                        parameter.alert = false;
                    }
                } else  {//для +
                    if (this.countPlusSigns(result)===1&&parameter.norm==='+') {
                        parameter.alert = false 
                    } else if(result.toLowerCase().includes('отсутств')) {
                        parameter.alert = false 
                    } else if(result!==parameter.norm) {
                        parameter.alert = true 
                    } else {
                        parameter.alert = false 
                    }
                    //parameter.alert = result!==parameter.norm ? true : false;
                }   
            }
        });
    },
    addResult(index) {
        let response = null;
        this.params[index-1].resultInput = false;
        if(this.params[index]){
            if (this.params[index].isTitle === undefined) {
                this.params[index].resultInput = true;
                response = index;
            }
            else if (this.params[index].isTitle&&this.params[index].isTitle === true) {
                this.params[index+1].resultInput = true;
                response = index+1;
            }
        }

        setTimeout(()=>{
            if(this.$refs['cell'+response]) {
                this.$refs['cell'+response][0].focus();
            }
        },0)
    },
    addResultUp(index) {
        let response = null;
        this.params[index+1].resultInput = false;
        if(this.params[index]){
            if (this.params[index].isTitle === undefined) {
                this.params[index].resultInput = true;
                response = index;
            }
            else if (this.params[index].isTitle&&this.params[index].isTitle === true) {
                this.params[index-1].resultInput = true;
                response = index-1;
            }
            
        }    
        setTimeout(()=>{
            if(this.$refs['cell'+response]) {
                this.$refs['cell'+response][0].focus();
            }
        },0)
    },
    addResultUnfocus(index){
        this.params[index].resultInput = false;
        this.focusChange = true;

        this.convertation();
    },
    showInput(index) {
        this.params[index].resultInput = true;
        setTimeout(()=>{
            this.$refs['cell'+index][0].focus();          
        },0) 
    },
    convertation() {
        setTimeout(()=>{
            this.focusChange = true;
            this.convertTable();
        },100);
    },
    closeMenu(index){
        setTimeout(()=>{
            this.params[index].menuOpen=false;
        },300);
    },
    convertTable(){
        function getTextFromHtml(element) {
            let text = '';
            if (element.nodeType === Node.TEXT_NODE) {
                text += element.textContent.trim();
            }
            element.childNodes.forEach(child => {
                text += getTextFromHtml(child);
            });

            return text;
        }

        let newTable = '';
        let i = 0           
        newTable += this.pregnant ? '<table><!-- pregnant urineTest -->' : '<table><!-- urineTest -->'
            this.$refs['table'].querySelectorAll('tr').forEach(row=>{
                if (!row.classList.contains('disabled')) {
                    let cols = row.querySelectorAll('td');                   
                    cols.forEach(col=>{
                        if(!col.classList.contains('controlCell')){
                            if(col.hasAttribute('colspan')) {
                                newTable += `<row><col colspan="${col.getAttribute('colspan')}" type="${col.getAttribute('type')}" ${col.getAttribute('bold') ? ` bold="${col.getAttribute('bold')}"` : ''}>${getTextFromHtml(col)}</col></row>`
                                i = 0;
                            }  
                            else {
                                if(i===0){
                                    newTable += '<row>'
                                }
                                if(col.hasAttribute('type')) {
                                    newTable += `<col ${col.getAttribute('bold') ? ` bold="${col.getAttribute('bold')}"` : ''} ${col.getAttribute('align') ? ` align="${col.getAttribute('align')}"` : ''} type="${col.getAttribute('type')}">${getTextFromHtml(col)}</col>`
                                }
                                else {
                                    newTable += `<col ${col.getAttribute('bold') ? ` bold="${col.getAttribute('bold')}"` : ''} ${col.getAttribute('align') ? ` align="${col.getAttribute('align')}"` : ''}>${getTextFromHtml(col)}</col>`
                                }
                                

                                if (i===2){         
                                    newTable += '</row>'
                                    i = 0;
                                } else {i++;}
                            }
                        }    
                    })
                }
            })
            
        newTable += '</table>';
        this.editorField.customValue = newTable;
        this.$parent.fieldUpdateSelectCustom(this.editorField);
        this.$parent.fieldRender();  

        setTimeout(()=>{
            this.focusChange = false;
        },0);  
    }
  }
}
</script>

<style scoped lang="scss">
.table {
    width:100%;
	margin-bottom: 20px;
	border: 1px solid #dddddd;
	border-collapse: collapse; 
    user-select: text !important;
    font-size: 14px;
}
.table th {
	font-weight: bold;
	padding: 2px;
	background: #efefef;
	border: 1px solid #dddddd;
}
.table td {
	border: 1px solid #dddddd;
	padding: 2px;
    text-align: center;
}
.table td:first-child {
    text-align: left;
}
.disabled {
    opacity: .3;
    transition: opacity .3s;
    &:hover {
        opacity: 1;
    }
}
.controlCell {
    cursor: pointer;
}
</style>